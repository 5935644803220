import 'assets/styles/app.scss';

import AOS from 'aos';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const loadedComponents = [];
document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}
} );

$(document).ready(function () {

	// Get text with class 'animate-text' ready to animate
	$(function () {
		const textToAnimate = $('.animate-text');

		textToAnimate.each(function() {
			$(this).splitLines({
				tag: '<div class="mask"><div class="line">',
				keepHtml: true
			});
		})
	});

	// Trigger to animate headers
	if($('.animate-text').length) {
		const animateText = document.querySelectorAll(".animate-text");
		animateText.forEach((section) => {
			gsap.to(section, {
				scrollTrigger: {
					trigger: section,
					markers: false,
					toggleClass: 'animate',
					start: "bottom 90%",
					once: true
				},
				opacity: 1,
			});
		});
	}

	(function() {
		// your page initialization code here
		// the DOM will be available here
		AOS.init({
			offset: 200, // offset (in px) from the original trigger point
			duration: 600, // values from 0 to 3000, with step 50ms
			easing: 'ease', // default easing for AOS animations
			once: true,
			anchorPlacement: 'top-bottom'
		});
	})();
});
